import { useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import useSettings from '../../hooks/useSettings';
import { THEMES } from '../../constants';
import MoonIcon from '../../icons/Moon';
import SunIcon from '../../icons/Sun';

/**
 * @description Toggle "Dark" / "Light" mode.
 * @returns {JSX.Element}
 */
const ThemePopover = () => {
  const { settings, saveSettings } = useSettings();
  const [selectedTheme, setSelectedTheme] = useState(settings.theme);
  const [values, setValues] = useState(settings);

  const handleSwitch = () =>
    setSelectedTheme((prevSelectedTheme) => {
      if (prevSelectedTheme === THEMES.LIGHT) {
        if (settings.theme === THEMES.LIGHT) {
          setValues({
            ...values,
            theme: THEMES.DARK,
          });
          saveSettings({
            ...values,
            theme: THEMES.DARK,
          });
          return THEMES.DARK;
        }
      }
      setValues({
        ...values,
        theme: THEMES.LIGHT,
      });
      saveSettings({
        ...values,
        theme: THEMES.LIGHT,
      });
      return THEMES.LIGHT;
    });

  useEffect(() => {
    setSelectedTheme(settings.theme);
  }, [settings.theme]);
  useEffect(() => {
    setValues(settings);
  }, [settings]);

  return (
    <>
      <Tooltip title="Switch themes">
        <IconButton onClick={handleSwitch}>
          {selectedTheme === 'LIGHT' ? <MoonIcon sx={{ color: 'white' }} fontSize="small" /> : <SunIcon color="white" fontSize="small" />}
        </IconButton>
      </Tooltip>
    </>
  );
};

export default ThemePopover;
