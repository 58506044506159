const notebookStatusTranslator = {
  Starting: 'starting',
  Pending: 'starting',
  Stopped: 'stopped',
  Failed: 'failed',
  InService: 'running',
  Stopping: 'stopping',
  'NOT FOUND': 'unknown',
  NotStarted: 'stopped',
};

const notebookStatus = {
  starting: 'starting',
  stopped: 'stopped',
  unknown: 'unknown',
  failed: 'failed',
  running: 'running',
  stopping: 'stopping',
  creating: 'creating',
};

export { notebookStatusTranslator, notebookStatus };
