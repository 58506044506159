import { useState, useEffect } from 'react';
import { ApolloClient, ApolloLink, InMemoryCache, HttpLink } from 'apollo-boost';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { from } from '@apollo/client';
import config from '../config';
import useToken from './useToken';

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const useClient = () => {
  const [client, setClient] = useState(null);
  const token = useToken();

  const initClient = async () => {
    const t = token;
    const httpLink = new HttpLink({
      uri: config.apiGateway.URL,
    });

    const authLink = new ApolloLink((operation, forward) => {
      operation.setContext({
        headers: {
          AccessControlAllowOrigin: '*',
          AccessControlAllowHeaders: '*',
          'access-control-allow-origin': '*',
          Authorization: t ? `${t}` : '',
          AccessKeyId: 'none',
          SecretKey: 'none',
        },
      });
      return forward(operation);
    });
    const errorLink = onError(({ graphQLErrors }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        });
      }
    });

    const retryLink = new RetryLink({
      delay: {
        initial: 200,
        max: Infinity,
        jitter: true,
      },
      attempts: {
        max: 20,
        retryIf: (error) => !!error,
      },
    });

    const apolloClient = new ApolloClient({
      link: from([errorLink, retryLink, authLink, httpLink]),
      cache: new InMemoryCache(),
      defaultOptions,
    });
    setClient(apolloClient);
  };

  useEffect(() => {
    if (token) {
      initClient().catch((e) => console.log(e));
    }
  }, [token]);
  return client;
};

export default useClient;
