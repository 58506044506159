import React, { FC, PropsWithChildren } from 'react';
import { Box, Button, Link, Typography } from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import { useNavigate } from 'react-router';
import useAuth from '../hooks/useAuth';

const LicenseRequired: FC<PropsWithChildren> = ({}: PropsWithChildren) => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  return (
    <>
      <Button sx={{ position: 'absolute', top: 10, right: 10, width: 0 }} color="primary" fullWidth onClick={handleLogout} variant="outlined">
        Logout
      </Button>
      <Box bgcolor={'#F8F8F8'} height={'100vh'} display="flex" flexDirection={'column'} textAlign={'center'} justifyContent="center" alignItems="center">
        <Box
          alt="Data Scientist"
          component="img"
          src={`/static/error/license_required.png`}
          sx={{
            borderRadius: '100%',
            borderTopLeftRadius: 0,
            height: 'auto',
            maxWidth: '100%',
            width: 330,
          }}
        />
        <Typography
          sx={{
            mt: 2,
          }}
          color="primary"
          variant="h5"
        >
          You need to request a
          <br />
          license to access Data Studio.
        </Typography>
        <Typography
          sx={{
            mt: 1,
          }}
          color="textSecondary"
          variant="body2"
        >
          {'Follow the instruction in the User Guide'}
        </Typography>
        <Button
          target="_blank"
          href="https://docs.google.com/forms/d/e/1FAIpQLSeQ0QrYNKWfNfybVShlsMS8WmBHXb0u4mZpxRGnbhaEvjHG2g/viewform"
          sx={{
            mt: 4,
          }}
          color="primary"
          size="large"
          variant="contained"
        >
          Get a license <Launch sx={{ ml: 1 }} fontSize="small" />
        </Button>
        <Link target="_blank" href={'https://sites.google.com/airliquide.com/gdo-datastudio/getting-started'} sx={{ mt: 1 }} fontSize={'12px'}>
          Learn more
        </Link>
        <Box
          alt="Air Liquide Datastudio"
          component="img"
          src={`/static/logo-stacked.png`}
          sx={{
            mt: 15,
            height: 'auto',
            maxWidth: '100%',
            width: 90,
          }}
        />
      </Box>
    </>
  );
};
export default LicenseRequired;
