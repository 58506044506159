import SvgIcon from '@mui/material/SvgIcon';

export default function DataPortalIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" d="M5 18L10 9.00003L11.7483 9.97131L6.74831 18.9713L5 18Z" fill="inherit" />
      <path
        fillRule="evenodd"
        d="M14.8791 12.155L20 15L20.5 13L20.3462 12.9044L15.8065 10.3823C15.6181 11.0385 15.299 11.6394 14.8791 12.155Z"
        fill="inherit"
      />
      <path fillRule="evenodd" d="M19.8841 11.0022C18.281 11.0631 17 12.382 17 14C17 15.144 17.6403 16.1384 18.5821 16.6444L19.8841 11.0022Z" fill="inherit" />
      <path
        fillRule="evenodd"
        d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        fill="inherit"
      />
      <path d="M14 9C14 10.6569 12.6569 12 11 12C9.34315 12 8 10.6569 8 9C8 7.34315 9.34315 6 11 6C12.6569 6 14 7.34315 14 9Z" fill="inherit" />
      <path d="M14 9C14 10.6569 12.6569 12 11 12C9.34315 12 8 10.6569 8 9C8 7.34315 9.34315 6 11 6C12.6569 6 14 7.34315 14 9Z" fill="inherit" />
      <path d="M9 18C9 19.6569 7.65685 21 6 21C4.34315 21 3 19.6569 3 18C3 16.3431 4.34315 15 6 15C7.65685 15 9 16.3431 9 18Z" fill="inherit" />
    </SvgIcon>
  );
}
