import { gql } from 'apollo-boost';

/**
 * @description Updates UserProfile object.
 * @param {UserProfile} input - Full filled object.
 * @returns {{mutation: , variables: {input}}}
 */
const updateUserProfile = (input) => ({
  variables: {
    input,
  },
  mutation: gql`
    mutation UpdateUserProfile($input: UserProfileInput!) {
      updateUserProfile(input: $input) {
        username
        bio
        b64EncodedAvatar
        hasAcceptedTermsOfUseAt
        tags
      }
    }
  `,
});

export default updateUserProfile;
