import React from 'react';
import PropTypes from 'prop-types';
import { Star, StarOutline } from '@material-ui/icons';
import { useTheme } from '@material-ui/core';

function StarButton({ fontSize = 'medium', starred, onStar, onUnStar }) {
  const theme = useTheme();
  return (
    <>
      {starred && (
        <Star
          fontSize={fontSize}
          color="primary"
          sx={{ cursor: 'pointer' }}
          onClick={(e) => {
            if (onUnStar) {
              e.stopPropagation();
              e.preventDefault();
              onUnStar();
            }
          }}
        />
      )}
      {!starred && (
        <StarOutline
          fontSize={fontSize}
          sx={{ color: theme.palette.text.secondary, cursor: 'pointer' }}
          onClick={(e) => {
            if (onStar) {
              e.stopPropagation();
              e.preventDefault();
              onStar();
            }
          }}
        />
      )}
    </>
  );
}

StarButton.propTypes = {
  fontSize: PropTypes.string,
  starred: PropTypes.bool,
  onStar: PropTypes.func,
  onUnStar: PropTypes.func,
};

export default StarButton;
