import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { THEMES } from '../constants';

const SettingsContext = createContext({
  settings: {},
  saveSettings: Function,
});

export function SettingsProvider(props) {
  const { children } = props;
  const initialSettings = {
    isAdvancedMode: false,
    compact: true,
    responsiveFontSizes: true,
    roundedCorners: true,
    theme: THEMES.LIGHT,
  };
  const [settings, setSettings] = useState();
  const [initiate, setInitiate] = useState(false);
  const settingStorageKey = 'settings';

  /**
   * @description store Settings in the local storage
   * @returns {void}
   */
  const storeSettings = () => {
    window.localStorage.setItem(settingStorageKey, JSON.stringify(settings));
  };

  /**
   * @description Save new settings
   * @param {Object} updatedSettings
   * @returns {void}
   */
  const saveSettings = (updatedSettings) => setSettings(updatedSettings);

  /**
   * @description Fetch current setting in local storage or create new settting if not stored
   * @returns {void}
   */
  const restoreSettings = () => {
    let newSettings = null;
    try {
      const storedData = window.localStorage.getItem(settingStorageKey);
      if (storedData) {
        newSettings = JSON.parse(storedData);
      } else {
        newSettings = {
          ...initialSettings,
          theme: THEMES.LIGHT,
        };
      }
      setSettings(newSettings);
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * @description Store settings when settings updated
   * @returns {void}
   */
  useEffect(() => {
    if (settings) {
      storeSettings(settings);
    }
  }, [settings]);

  /**
   * @description Restore setting when mounted
   * @returns {void}
   */
  useEffect(() => {
    if (!initiate) {
      setInitiate(true);
      restoreSettings();
    }
  }, []);

  return (
    <SettingsContext.Provider
      value={{
        settings,
        saveSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
