import merge from 'lodash/merge';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { THEMES } from '../constants';
import { lightShadows, darkShadows } from './shadows';

const baseOptions = {
  direction: 'ltr',
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '10px 14px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
        },
        input: {
          '&::placeholder': {
            opacity: 0.86,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
          '& label': {
            fontSize: '0.8rem',
            top: '-5px',
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          background: 'primary',
          padding: '8px 16px',
          borderRadius: '4px',
          textTransform: 'none',
          fontSize: '12px',
          lineHeight: '18px',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px',
        },
      },
    },
  },
  typography: {
    button: {
      fontWeight: 500,
    },
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    h1: {
      fontWeight: 400,
      fontSize: '2.5rem',
    },
    h2: {
      fontWeight: 400,
      fontSize: '2.25rem',
    },
    h3: {
      fontWeight: 400,
      fontSize: '2rem',
    },
    h4: {
      fontWeight: 400,
      fontSize: '1.75rem',
    },
    h5: {
      fontWeight: 400,
      fontSize: '1.3rem',
    },
    h6: {
      fontWeight: 400,
      fontSize: '1.125rem',
    },
    overline: {
      fontWeight: 600,
    },
  },
};

const themesOptions = {
  [THEMES.LIGHT]: {
    components: {},
    palette: {
      action: {
        active: '#6b778c',
      },
      background: {
        default: '#ffffff',
        paper: '#ffffff',
        hover: '#f7f7f7',
      },
      error: {
        contrastText: '#ffffff',
        main: '#f44336',
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: '#375f9b',
      },
      neutral: {
        contrastText: '#ffffff',
        main: '#b0b0b0',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50',
      },
      text: {
        primary: '#172b4d',
        secondary: '#6b778c',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ec7211',
      },
    },
    shadows: lightShadows,
  },
  [THEMES.DARK]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
          },
        },
      },
    },
    palette: {
      background: {
        default: '#1b2635',
        paper: '#233044',
        hover: '#1b2635',
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336',
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#ec7211',
      },
      neutral: {
        contrastText: '#ffffff',
        main: '#b0b0b0',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50',
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800',
      },
    },
    shadows: darkShadows,
  },
};

/**
 * @description Creates theme configuration.
 * @param {Object} config
 * @returns {Theme}
 */
export const createUITheme = (config = {}) => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  let theme = createMuiTheme(
    merge({}, baseOptions, themeOptions, {
      direction: config.direction,
    }),
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
