import { gql } from 'apollo-boost';

/**
 * @description Creates new DatasetBookmark.
 * @param {string} datasetUri - Dataset URI.
 * @returns {Object}
 */
const createMyDatasetBookmark = (datasetUri) => ({
  variables: {
    datasetUri,
  },
  mutation: gql`
    mutation CreateMyDatasetBookmark($datasetUri: String!) {
      createMyDatasetBookmark(datasetUri: $datasetUri) {
        datasetBookmarkUri
        datasetUri
        createdAt
        updatedAt
        deletedAt
      }
    }
  `,
});

export default createMyDatasetBookmark;
