import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTour } from '@reactour/tour';
import { AppBar, Box, Toolbar, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { Menu, Tour } from '@material-ui/icons';
import PropTypes from 'prop-types';
import AccountPopover from '../popovers/AccountPopover';
import Logo from '../Logo';
import NotificationsPopover from '../popovers/NotificationsPopover';
import ThemePopover from '../popovers/ThemePopover';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  pulse: {
    background: '',
    borderRadius: '50%',
    margin: 10,
    height: 20,
    width: 20,
    boxShadow: '0 0 0 0 rgba(255, 255, 255, 1)',
    transform: 'scale(1)',
    animation: '$pulse 2s infinite',
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(255, 255, 255, 0.7)',
    },
    '70%': {
      transform: 'scale(1)',
      boxShadow: '0 0 0 10px rgba(255, 255, 255, 0)',
    },
    '100%': {
      transform: 'scale(0.95)',
      boxShadow: '0 0 0 0 rgba(255, 255, 255, 0)',
    },
  },
}));

const DefaultNavbar = ({ openDrawer, onOpenDrawerChange }) => {
  const classes = useStyles();
  const { setIsOpen, setCurrentStep } = useTour();

  const [tourTriggered, setTourTriggered] = useState(localStorage.getItem('data-studio-has-triggered-tour'));

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar sx={{ minHeight: 64, maxHeight: 64 }}>
        {!openDrawer && (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => {
              onOpenDrawerChange(true);
            }}
          >
            <Menu />
          </IconButton>
        )}
        <Box width="350px" display={{ xs: 'block', lg: 'block', xl: 'block' }}>
          <Link to="/">
            <Logo />
          </Link>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        <Box sx={{ ml: 1 }}>
          <Tooltip className={!tourTriggered && classes.pulse} title="Start tour">
            <IconButton
              onClick={() => {
                setTourTriggered(true);
                localStorage.setItem('data-studio-has-triggered-tour', true);
                setCurrentStep(0); // Reset Tour
                setIsOpen(true);
              }}
            >
              <Tour sx={{ color: 'white' }} fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ ml: 1 }}>
          <ThemePopover />
        </Box>
        <Box sx={{ ml: 1 }}>
          <NotificationsPopover />
        </Box>
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

DefaultNavbar.propTypes = {
  openDrawer: PropTypes.bool,
  onOpenDrawerChange: PropTypes.func,
};

export default DefaultNavbar;
