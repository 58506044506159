import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Typography } from '@material-ui/core';
import * as BsIcons from 'react-icons/bs';
import Button from '@mui/material/Button';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import StarButton from '../StarButton';
import { BookmarkContext } from '../../providers/BookmarkProvider';
import config from '../../config';

/**
 * @description Display a dataset bookmark
 * @return {JSX.Element}
 */
export default function StarDatasetItem({ datasetBookmark }) {
  const [starred, setStarred] = useState(true);
  const [showActions, setShowActions] = useState(false);
  const { createDatasetBookmark, deleteDatasetBookmark } = useContext(BookmarkContext);
  const location = useLocation();

  /**
   * @description Add a bookmark on the dataset when the star his clicked
   * @return {Promise<void>}
   */
  const star = async () => {
    try {
      setStarred(true);

      await createDatasetBookmark(datasetBookmark.dataset.datasetUri);
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * @description Delete a bookmark on the dataset when the star his clicked
   * @return {Promise<void>}
   */
  const unStar = async () => {
    try {
      setStarred(false);
      await deleteDatasetBookmark(datasetBookmark.datasetBookmarkUri);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      {datasetBookmark && (
        <Button
          onMouseEnter={() => {
            setShowActions(true);
          }}
          onMouseLeave={() => {
            setShowActions(false);
          }}
          component={RouterLink}
          sx={{ display: 'flex', alignItems: 'center', pt: 1, pb: 1, pr: 1, pl: 2, justifyContent: 'flex-start', borderRadius: 0 }}
          to={location.pathname}
          onClick={() => window.open(`${config.links.dataPortal}/datasets/${datasetBookmark.dataset.datasetUri}`, '_blank')}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            <Box sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
              <BsIcons.BsFolder size={15} />
            </Box>
            <Typography
              color="textPrimary"
              variant="body2"
              sx={{
                textOverflow: 'ellipsis',
                maxWidth: '175px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {datasetBookmark.dataset.label}
            </Typography>
          </Box>
          {showActions && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <StarButton fontSize="small" starred={starred} onStar={star} onUnStar={unStar} />
            </Box>
          )}
        </Button>
      )}
    </>
  );
}

StarDatasetItem.propTypes = {
  datasetBookmark: PropTypes.object,
};
