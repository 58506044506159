import React, { useEffect, useState } from 'react';
import * as BsIcons from 'react-icons/bs';
import * as FaIcons from 'react-icons/fa';
import * as BiIcons from 'react-icons/bi';
import { MdShowChart } from 'react-icons/md';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Drawer, Typography, IconButton, useTheme } from '@material-ui/core';
import { AiOutlineExperiment } from 'react-icons/ai';
import { SiJupyter } from 'react-icons/si';
import { VscBook } from 'react-icons/vsc';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import config from '../../config';
import useSettings from '../../hooks/useSettings';
import CopyIconButton from '../CopyIconButton';
import StarredMenu from './StarredMenu';
import DataPortalIcon from '../../icons/DataPortal';
import { notes } from '../../views/ReleaseNotes/constants';
import OpenMmsTicket from '../OpenMmsTicket';
import useGroups from '../../hooks/useGroups';
import { UserGroups } from '../../dictionaries/userGroups';
import useKeyboardShortcut from 'use-keyboard-shortcut';

/**
 * @description DefaultSidebar component.
 * @param {DefaultSidebar.propTypes} props
 * @return {JSX.Element}
 */
const DefaultSidebar = (props) => {
  /**
   * @description Returns sections according to the mode.
   * @param {Boolean} isAdvancedMode
   * @returns {({title: string, items: [{path: string, icon, title: string}, {path: string, icon, title: string}, {path: string, icon, title: string}, {path: string, icon, title: string}]}|{title: string, items: [{path: string, icon, title: string}, {path: string, icon, title: string}, {path: string, icon, title: string}, {path: string, icon, title: string}, {path: string, icon, title: string}]}|{title: string, items: [{path: string, icon, title: string}, {path: string, icon, title: string}]})[]|({title: string, items: [{path: string, icon, title: string}, {path: string, icon, title: string}, {path: string, icon, title: string}, {path: string, icon, title: string}]}|{title: string, items: [{path: string, icon, title: string}, {path: string, icon, title: string}]})[]}
   */
  const DATA_PORTAL_PATH = '/datasets-on-data-portal';
  const SECTIONS = [
    {
      title: 'Play',
      items: [
        {
          title: 'Notebooks',
          path: '/notebooks',
          tour: 'step-notebooks',
          icon: <SiJupyter size={15} />,
        },
        {
          title: 'SQL Worksheets',
          path: '/worksheets',
          tour: 'step-worksheets',
          icon: <AiOutlineExperiment size={15} />,
        },
        {
          title: 'ML Studio',
          path: '/sagemakerstudio/userprofile',

          icon: <BsIcons.BsFileCode size={15} />,
          betaAccess: true,
          admin: true,
        },
        {
          title: 'Pipelines',
          path: '/pipelines',
          icon: <BsIcons.BsGear size={15} />,
          admin: true,
        },
        {
          title: 'Dashboards',
          path: '/dashboards',
          icon: <MdShowChart size={15} />,
          admin: true,
        },
      ],
    },
    {
      title: 'Data',
      items: [
        {
          title: 'Datasets',
          path: '/datasets',
          tour: 'step-datasets',
          icon: <VscBook size={15} />,
          admin: true,
        },
        {
          title: 'Datasets (on Data Portal)',
          path: DATA_PORTAL_PATH,
          tour: 'step-datasets',
          icon: <DataPortalIcon sx={{ fontSize: '15px !important' }} />,
          userOnly: true,
        },
        {
          title: 'My Datasets',
          path: '/my-datasets',
          tour: 'step-my-datasets',
          icon: <BsIcons.BsFolder size={15} />,
        },
        {
          title: 'Access Requests',
          path: '/access-requests',
          tour: 'step-access-requests',
          icon: <FaIcons.FaExchangeAlt size={15} />,
          admin: true,
        },
        {
          title: 'Organize',
          path: '/glossaries',
          icon: <BsIcons.BsTag size={15} />,
          admin: true,
        },
      ],
    },
    {
      title: 'Admin',
      admin: true,
      items: [
        {
          title: 'Organizations',
          path: '/organizations',
          icon: <BiIcons.BiBuildings size={15} />,
        },
        {
          title: 'Workspaces',
          path: '/workspaces',
          icon: <BsIcons.BsCloud size={15} />,
        },
      ],
    },
    {
      title: 'Monitoring',
      admin: true,
      items: [
        {
          title: 'AWS Consumption Cost',
          path: '/cost-report',
          tour: 'step-cost',
          icon: <BiIcons.BiDollarCircle size={15} />,
        },
      ],
    },
  ];

  const { openDrawer, onOpenDrawerChange } = props;
  const location = useLocation();
  const groups = useGroups();
  const navigate = useNavigate();
  const { settings, saveSettings } = useSettings();
  const [displayCollapser, setDisplayCollapser] = useState(false);
  const [hoveringSupport, setHoveringSupport] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (location.pathname === DATA_PORTAL_PATH) {
      window.open(`${config.links.dataPortal}/datasets`, '_blank');
      navigate(-1);
    }
  }, [location]);

  const isAdvancedUser = () => groups.includes(UserGroups.DH_ADMINISTRATORS) || groups.includes(UserGroups.DH_ADVANCED_USERS);

  /**
   * Enforce user to be in advancedMode only if allowed
   */
  useEffect(() => {
    if (groups) {
      if (!isAdvancedUser()) {
        saveSettings({
          ...settings,
          isAdvancedMode: false,
        });
      }
    }
  }, [groups]);

  /**
   * @description Toggle advanced mode when keyboard easter eggs trigger (Shift + Z) for Mac and Windows.
   * Limited to DH_ADMINISTRATORS & DH_ADVANCED_USERS
   * @param {string[]}
   * @param {Function}
   * @returns {void}
   */
  useKeyboardShortcut(
    ['Shift', 'Z'],
    () => {
      if (isAdvancedUser()) {
        saveSettings({
          ...settings,
          isAdvancedMode: !settings.isAdvancedMode,
        });
      }
    },
    {
      overrideSystem: true,
      ignoreInputFields: true,
      repeatOnHold: false,
    },
  );

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100%)',
        width: '250px',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ p: 2 }}>
          {SECTIONS.map((section) => (
            <NavSection key={section.title} pathname={location.pathname} {...section} />
          ))}
        </Box>
        {settings.isAdvancedMode && (
          <Box sx={{ px: 2, pb: 2 }}>
            <Button color="primary" component="a" fullWidth sx={{ mt: 2 }} href={config.links.developerDocumentation} target="_blank" variant="contained">
              Technical Guide
            </Button>
          </Box>
        )}
        <StarredMenu />
      </Scrollbar>
      <Divider />
      <Box
        sx={{ p: 2 }}
        style={{ position: 'relative' }}
        onMouseEnter={() => {
          setHoveringSupport(true);
        }}
        onMouseLeave={() => {
          setHoveringSupport(false);
        }}
      >
        <Typography color="textPrimary" variant="body1">
          Support Email
        </Typography>
        {hoveringSupport && (
          <Box style={{ position: 'absolute', right: 5, top: 5 }}>
            <CopyIconButton text="ww-support-datastudio@airliquide.com" />
          </Box>
        )}
        <Typography color="textPrimary" variant="body2">
          <span style={{ fontSize: 12 }}>ww-support-datastudio@airliquide.com</span>
        </Typography>
        <Box sx={{ pb: 2 }}>
          <Button
            data-tour="step-user-guide"
            color="primary"
            component="a"
            fullWidth
            sx={{ mt: 1 }}
            href={config.links.userGuide}
            target="_blank"
            variant="contained"
          >
            User Guide
          </Button>
        </Box>
        <Typography style={{ fontSize: 12 }} color="textPrimary" variant="body2">
          DataStudio{' '}
          <Link to="/release-notes">
            <b color="primary">{notes[0].version}</b>
          </Link>{' '}
          | <OpenMmsTicket />
        </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      <Drawer
        anchor="left"
        open={openDrawer}
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
          },
        }}
        variant="temporary"
        sx={{
          display: { xs: 'block', sm: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100vw' },
        }}
      >
        <Box>
          <IconButton
            onClick={() => {
              onOpenDrawerChange(false);
            }}
          >
            {openDrawer}
            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </Box>
        {content}
      </Drawer>
      <Box display={{ xs: 'none', md: 'block' }}>
        <Drawer
          anchor="left"
          open={openDrawer}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              pt: 8,
              overflow: 'visible',
            },
          }}
          variant="persistent"
          onMouseEnter={() => {
            setDisplayCollapser(true);
          }}
          onMouseLeave={() => {
            setDisplayCollapser(false);
          }}
        >
          {displayCollapser && (
            <Box
              sx={{
                position: 'absolute',
                right: -25,
                top: 100,
                zIndex: 2000,
                backgroundColor: 'background.paper',
                borderColor: `${theme.palette.divider} !important`,
                transform: 'scale(0.7)',
                borderRight: 1,
                borderBottom: 1,
                borderTop: 1,
                borderLeft: 1,
                borderRadius: 50,
              }}
            >
              <IconButton
                onClick={() => {
                  onOpenDrawerChange(false);
                }}
              >
                {openDrawer}
                {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
              </IconButton>
            </Box>
          )}
          {content}
        </Drawer>
      </Box>
    </>
  );
};

DefaultSidebar.propTypes = {
  openDrawer: PropTypes.bool,
  onOpenDrawerChange: PropTypes.func,
};

export default DefaultSidebar;
