import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Button, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import getUserProfile from '../api/UserProfile/getUserProfile';
import updateUserProfile from '../api/UserProfile/updateUserProfile';
import useClient from '../hooks/useClient';
import { SET_ERROR } from '../store/errorReducer';
import { useDispatch } from '../store';
import { dayjs } from '../utils/dayjs';

/**
 * @description TermsOfUse component.
 * @returns {JSX.Element}
 */
function TermsOfUse() {
  const client = useClient();
  const dispatch = useDispatch();
  const [displayNotificationModal, setDisplayNotificationModal] = useState(false);

  /**
   * @description Retrieve UserProfile
   * @returns {Promise<void>}
   */
  const initialize = async () => {
    const response = await client.query(getUserProfile());

    if (response?.errors) {
      dispatch({ type: SET_ERROR, error: response.errors[0].message });
    }

    if (!response?.data.getUserProfile.hasAcceptedTermsOfUseAt) {
      setDisplayNotificationModal(true);
    }
  };

  /**
   * @description Accept terms of use.
   * @returns {Promise<void>}
   */
  const accept = async () => {
    await client.mutate(updateUserProfile({ hasAcceptedTermsOfUseAt: dayjs.utc().format() }));
    setDisplayNotificationModal(false);
  };

  /**
   * @description Initialize component on "client" change.
   * @returns {void}
   */
  useEffect(() => {
    if (client) {
      initialize();
    }
  }, [client]);

  return (
    <Dialog open={displayNotificationModal}>
      <DialogContent>
        <Box sx={{ mb: 1 }}>
          <Typography color="textPrimary">By using Data Studio, you acknowledge and agree to the Terms of Use.</Typography>
        </Box>
        <a
          href={'https://docs.google.com/presentation/d/1SYe6DTO-mcio3zdu98YwvqVvoGRXrshwsLnImDLEXq4/edit?usp=sharing'}
          target="_blank"
          rel="noreferrer"
          style={{ textDecorationLine: 'underline' }}
        >
          Terms of use
        </a>
        <DialogActions>
          <Button onClick={() => accept()}>I agree</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default TermsOfUse;
