import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import useClient from '../hooks/useClient';
import getSagemakerNotebook from '../api/SagemakerNotebook/getSagemakerNotebook';
import useInterval from '../hooks/useInterval';
import getSagemakerNotebookPresignedUrl from '../api/SagemakerNotebook/getSagemakerNotebookPresignedUrl';
import { notebookStatus, notebookStatusTranslator } from '../dictionaries/notebookStatus';
import startSagemakerNotebook from '../api/SagemakerNotebook/startNotebookInstance';
import stopSagemakerNotebook from '../api/SagemakerNotebook/stopNotebookInstance';
import { NotificationContext } from './NotificationProvider';

const NotebookControllerContext = createContext({});

function NotebookControllerProvider({ children }) {
  const client = useClient();
  const [watchers, setWatchers] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const { requestNotificationPermission, setOnNotificationGranted, pushNotification } = useContext(NotificationContext);

  /**
   * @description fetch the Sagemaker url and open the notebook instance in another tab
   * @returns {Promise<void>}
   */
  const openNotebook = async (notebookId) => {
    let newWatchers = { ...watchers };
    newWatchers[notebookId].opening = true;
    setWatchers(newWatchers);
    try {
      const response = await client.query(getSagemakerNotebookPresignedUrl(notebookId));
      window.open(response.data.getSagemakerNotebookPresignedUrl);
      newWatchers = { ...watchers };
      newWatchers[notebookId].opening = false;
      setWatchers(newWatchers);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * @description display a text notification and play a sound notification when the notebook is started
   * @param {string} notebookId
   * @param {string} notebookLabel
   * @returns {void}
   */
  const onStart = async (notebookId, notebookLabel) => {
    pushNotification(`Notebook ${notebookLabel} started`);
    await openNotebook(notebookId);
  };

  /**
   * @description Fetch a notebook and set is status
   * @param {string} notebookId
   * @returns {void}
   */
  const fetchNotebook = async (notebookId) => {
    try {
      const response = await client.query(getSagemakerNotebook(notebookId));
      const notebook = response.data.getSagemakerNotebook;
      if (notebookStatusTranslator[notebook.NotebookInstanceStatus] === notebookStatus.running && watchers[notebookId].status !== notebookStatus.running) {
        await onStart(notebookId, notebook.label);
      }
      const newWatchers = { ...watchers };
      newWatchers[notebookId].status = notebookStatusTranslator[notebook.NotebookInstanceStatus];
      setWatchers(newWatchers);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * @description start watching the status of the notebook
   * @param {string} notebookId
   * @param {string} notebookLabel
   * @param {string} originalStatus
   * @returns {void}
   */
  const watchStatus = async (notebookId, notebookLabel, originalStatus) => {
    setWatchers({
      ...watchers,
      [notebookId]: {
        // In case of an unknown status to not freeze the view
        status: notebookStatusTranslator[originalStatus] || 'N/A',
        opening: false,
      },
    });
  };

  /**
   * @description Update all notebooks.
   * @returns {Promise<void>}
   */
  useInterval(() => Object.keys(watchers).map((notebookId) => fetchNotebook(notebookId)), 30000);

  /**
   * @description Start the notebook instance
   * @param {string} notebookId
   * @returns {void}
   */
  const startNotebook = async (notebookId) => {
    try {
      await client.mutate(startSagemakerNotebook(notebookId));
      await fetchNotebook(notebookId);
      requestNotificationPermission();
      setOnNotificationGranted(() => {
        enqueueSnackbar(
          'Notebook starting. It will take about 5 minutes to start. While waiting you contribute to save cost and contribute to a more carbon neutral company.',
          {
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
          },
        );
      });
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * @description Stop the notebook instance
   * @param {string} notebookId
   * @param {string} notebookLabel
   * @returns {void}
   */
  const stopNotebook = async (notebookId, notebookLabel) => {
    try {
      await client.mutate(stopSagemakerNotebook(notebookId));
      await fetchNotebook(notebookId);
      enqueueSnackbar(`Notebook ${notebookLabel} stopping`, {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * @description Return the status of the notebook instance
   * @param {string} notebookId
   * @returns {string | null}
   */
  const getStatus = (notebookId) => {
    if (watchers[notebookId] && watchers[notebookId].status) {
      return watchers[notebookId].status;
    }
    return null;
  };

  /**
   * @description Return if the notebook instance is opening or not
   * @param {string} notebookId
   * @returns {boolean}
   */
  const isNotebookOpening = (notebookId) => {
    if (watchers[notebookId] && watchers[notebookId].opening) {
      return watchers[notebookId].opening;
    }
    return false;
  };

  return (
    <NotebookControllerContext.Provider
      value={{
        watchStatus,
        startNotebook,
        stopNotebook,
        openNotebook,
        watchers,
        getStatus,
        isNotebookOpening,
      }}
    >
      {children}
    </NotebookControllerContext.Provider>
  );
}

NotebookControllerProvider.propTypes = {
  children: PropTypes.node,
};

export { NotebookControllerProvider, NotebookControllerContext };
