import { Alert, useTheme } from '@material-ui/core';
import { TourProvider } from '@reactour/tour';
import { useNavigate } from 'react-router';

type UserGuideProviderProps = {
  children: React.ReactNode;
};

const UserGuideProvider = ({ children }: UserGuideProviderProps) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const steps = [
    {
      selector: '[data-tour="step-datasets"]',
      content: <p>Browse the data catalog and request access to items on demand trough Data Portal.</p>,
    },
    {
      selector: '[data-tour="step-my-datasets"]',
      content: 'Browse the data items that you have access to or that you own.',
      action: () => navigate('/my-datasets'),
    },
    {
      selector: '[data-tour="step-my-datasets-search"]',
      content: (
        <p>
          Looking for a specific data item?
          <br />
          The search bar is at your disposal.
        </p>
      ),
      action: () => navigate('/my-datasets'),
    },
    {
      selector: '[data-tour="step-worksheets"]',
      content: 'Dig into your data sets using SQL',
      action: () => navigate('/worksheets'),
    },
    {
      selector: '[data-tour="step-worksheets-create"]',
      content: (
        <p>
          First time?
          <br />
          This button allows to create an SQL Worksheet.
          <Alert severity="info">This service is powered by the Amazon Athena query engine, with Apache Presto under the hood.</Alert>
        </p>
      ),
      action: () => navigate('/worksheets'),
    },
    {
      selector: '[data-tour="step-notebooks"]',
      content: 'Leverage your data within JupyterLab (remotely hosted in AWS)',
      action: () => navigate('/notebooks'),
    },
    {
      selector: '[data-tour="step-notebook-create"]',
      content: (
        <p>
          You can create a DataStudio Notebook using this button.
          <br />
          Please note that custom Notebook kernels are a useful feature.
        </p>
      ),
      action: () => navigate('/notebooks'),
    },
    {
      selector: '[data-tour="step-feedback"]',
      content: <p>Please let us know if you have any issue or topic of improvement to share, by submitting feedback.</p>,
    },
    {
      selector: '[data-tour="step-user-guide"]',
      content: <p>Enjoy 🤙, refer to the User Guide at any moment if needed.</p>,
    },
  ];

  return (
    <TourProvider
      inViewThreshold={{ x: 0, y: 200 }}
      steps={steps}
      disableInteraction
      disableDotsNavigation
      prevButton={() => <></>}
      styles={{
        maskWrapper: (base) => ({
          ...base,
          opacity: 0.3,
        }),
        popover: (base) => ({ ...base, color: 'black' }),
        badge: (base) => ({ ...base, backgroundColor: theme.palette.primary.main }),
        dot: (base, { current }) => ({ ...base, backgroundColor: current ? theme.palette.primary.main : 'white' }),
      }}
    >
      {children}
    </TourProvider>
  );
};

export default UserGuideProvider;
