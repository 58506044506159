import { gql } from 'apollo-boost';

/**
 * @description Deletes DatasetBookmark.
 * @param {string} datasetBookmarkUri - DatasetBookmark URI.
 * @returns {Object}
 */
const deleteMyDatasetBookmark = (datasetBookmarkUri) => ({
  variables: {
    datasetBookmarkUri,
  },
  mutation: gql`
    mutation DeleteMyDatasetBookmark($datasetBookmarkUri: String!) {
      deleteMyDatasetBookmark(datasetBookmarkUri: $datasetBookmarkUri) {
        datasetBookmarkUri
        datasetUri
        createdAt
        updatedAt
        deletedAt
      }
    }
  `,
});

export default deleteMyDatasetBookmark;
