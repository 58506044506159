import { Box, Typography, useTheme } from '@material-ui/core';
import React, { FC } from 'react';
import { MdInfoOutline } from 'react-icons/md';

interface InfobarProps {
  display: boolean;
}

const Infobar: FC<InfobarProps> = ({ display }) => {
  const theme = useTheme();

  return (
    <>
      {display ? (
        <Box sx={{ p: 2, borderBottom: `solid 1px ${theme.palette.divider}`, backgroundColor: '#FFF3E0' }} color={'#5c2900'}>
          <MdInfoOutline style={{ display: 'inline-block', verticalAlign: 'middle' }} size={20} />
          <Typography color={'#5c2900'} style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: 5 }}>
            Due to SAP Align migration occurring on April 8, databases sourced on SAP OPERA will be refreshed in new datasets (V2). Click{' '}
            <a rel="noreferrer" target="_blank" href="https://docs.google.com/document/d/1Yyz90uaseiwaxFV89Jcd3Ffn4T7Ej3Aqw_Cu-aaYSbs/view">
              here
            </a>{' '}
            for more details.
          </Typography>
        </Box>
      ) : null}
    </>
  );
};

export default Infobar;
