import React, { createContext, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from '@material-ui/core';
import { Howl } from 'howler';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const NotificationContext = createContext({});

/**
 * @description Wrapper for notification permission request with a notification context hook to access permission within children components and a modal to request permissions
 * @param {Object} props
 * @param {Node} props.children
 * @returns {JSX.Element}
 */
function NotificationProvider({ children }) {
  const [hasBrowserPermission, setHasBrowserPermission] = useState(false);
  const [onGranted, setOnNotificationGranted] = useState(null);
  const [displayNotificationModal, setDisplayNotificationModal] = useState(false);
  const [textPermission, setTextPermission] = useState(true);
  const [soundPermission, setSoundPermission] = useState(true);
  const sound = new Howl({
    src: ['/static/notification.wav'],
    volume: 0.3,
  });

  /**
   * @description Push text and/or sound notification
   * @param {string} text
   * @return {void}
   */
  const pushNotification = (text, body, onClick) => {
    const soundNotification = localStorage.getItem('soundNotificationPermission') === 'true';
    const textNotification = localStorage.getItem('textNotificationPermission') === 'true';
    if (soundNotification) {
      sound.play();
    }
    if (textNotification) {
      const img = '/static/logo-compact.png';
      const notification = new Notification(text, {
        icon: img,
        body,
      });
      notification.onClick = onClick;
    }
  };

  /**
   * @description Trigger the "onGranted" method if user accepted browser notification permissions
   * @param {string} permission
   * @return {void}
   */
  const handlePermission = (permission) => {
    if (permission === 'denied' || permission === 'default') {
      setHasBrowserPermission(false);
    } else {
      setHasBrowserPermission(true);
      if (onGranted) {
        onGranted();
      }
    }
  };

  /**
   * @description Check support of notification promise
   * @return {boolean}
   */
  const checkNativeNotificationPromiseSupport = async () => {
    try {
      await Notification.requestPermission();
    } catch (e) {
      return false;
    }
    return true;
  };

  /**
   * @description Request the native permission modal and trigger the "handlePermission" method after user choice
   * @return {void}
   */
  const requestBrowserTextNotificationPermission = async () => {
    let permission;
    try {
      if (!('Notification' in window)) {
        console.error('This browser does not support notifications.');
      } else {
        const promiseNativeSupport = await checkNativeNotificationPromiseSupport();
        if (promiseNativeSupport) {
          permission = await Notification.requestPermission();
        } else {
          permission = await new Promise((resolve) => {
            Notification.requestPermission((perm) => {
              resolve(perm);
            });
          });
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      if (permission) {
        handlePermission(permission);
      }
    }
  };

  /**
   * @description Trigger the display of the notification permission modal if currently no permission
   * @return {void}
   */
  const requestNotificationPermission = () => {
    const neverRequest = localStorage.getItem('textNotificationPermission') === null;
    const differenceBrowserUser = localStorage.getItem('textNotificationPermission') === 'true' && !hasBrowserPermission;
    if (differenceBrowserUser || neverRequest) {
      setDisplayNotificationModal(true);
    }
  };

  /**
   * @description Set user's permission text and sound choice in local storage
   * @return {void}
   */
  const setPermissions = async () => {
    setDisplayNotificationModal(false);
    if (soundPermission) {
      localStorage.setItem('soundNotificationPermission', 'true');
    } else {
      localStorage.setItem('soundNotificationPermission', 'false');
    }
    if (textPermission) {
      localStorage.setItem('textNotificationPermission', 'true');
      await requestBrowserTextNotificationPermission();
    } else {
      localStorage.setItem('textNotificationPermission', 'false');
    }
  };

  /**
   * @description set the notification permission based on the browser permission
   * @return {void}
   */
  useEffect(() => {
    if (Notification.permission === 'denied' || Notification.permission === 'default') {
      setHasBrowserPermission(false);
    } else {
      setHasBrowserPermission(true);
    }
  });

  return (
    <NotificationContext.Provider value={{ requestNotificationPermission, setOnNotificationGranted, pushNotification }}>
      <Dialog open={displayNotificationModal}>
        <DialogTitle id="alert-dialog-title">Notification Permission</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            It takes about 10 minutes to start a notebook. Do you want to be notified when notebook is started ?
          </DialogContentText>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={textPermission}
                  onChange={() => {
                    setTextPermission(!soundPermission);
                  }}
                  defaultChecked
                />
              }
              label="Text"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={soundPermission}
                  onChange={() => {
                    setSoundPermission(!soundPermission);
                  }}
                  defaultChecked
                />
              }
              label="Sound"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDisplayNotificationModal(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={setPermissions}>Validate</Button>
        </DialogActions>
      </Dialog>
      {children}
    </NotificationContext.Provider>
  );
}

NotificationProvider.propTypes = {
  children: PropTypes.node,
};

export { NotificationProvider, NotificationContext };
