import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router';
import AuthGuard from './components/AuthGuard';
import LoadingScreen from './components/LoadingScreen';
import DefaultLayout from './components/layout/DefaultLayout';

// eslint-disable-next-line react/display-name
const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Error pages
const NotFound = Loadable(lazy(() => import('./views/NotFound')));

const OrganizationList = Loadable(lazy(() => import('./views/Organizations/OrganizationList')));
const OrganizationView = Loadable(lazy(() => import('./views/Organizations/OrganizationView')));
const OrganizationCreateForm = Loadable(lazy(() => import('./views/Organizations/OrganizationCreateForm')));
const OrganizationEditForm = Loadable(lazy(() => import('./views/Organizations/OrganizationEditForm')));
const EnvironmentCreateForm = Loadable(lazy(() => import('./views/Environments/EnvironmentCreateForm')));
const EnvironmentEditForm = Loadable(lazy(() => import('./views/Environments/EnvironmentEditForm')));
const EnvironmentView = Loadable(lazy(() => import('./views/Environments/EnvironmentView')));
const EnvironmentList = Loadable(lazy(() => import('./views/Environments/EnvironmentList')));
const Datasets = Loadable(lazy(() => import('./views/Catalog/Catalog')));

const DatasetList = Loadable(lazy(() => import('./views/Datasets/DatasetList')));
const DatasetView = Loadable(lazy(() => import('./views/Datasets/DatasetView')));
const DatasetCreateForm = Loadable(lazy(() => import('./views/Datasets/DatasetCreateForm')));
const DatasetImportForm = Loadable(lazy(() => import('./views/Datasets/DatasetImportForm')));
const DatasetEditForm = Loadable(lazy(() => import('./views/Datasets/DatasetEditForm')));
const TableView = Loadable(lazy(() => import('./views/Tables/TableView')));
const TableEditForm = Loadable(lazy(() => import('./views/Tables/TableEditForm')));

const FolderCreateForm = Loadable(lazy(() => import('./views/Folders/FolderCreateForm')));
const FolderView = Loadable(lazy(() => import('./views/Folders/FolderView')));
const FolderEditForm = Loadable(lazy(() => import('./views/Folders/FolderEditForm')));

const NotebookList = Loadable(lazy(() => import('./views/Notebooks/NotebookList')));
const NotebookView = Loadable(lazy(() => import('./views/Notebooks/NotebookView')));
const NotebookCreateForm = Loadable(lazy(() => import('./views/Notebooks/NotebookCreateForm')));

const SagemakerStudioList = Loadable(lazy(() => import('./views/SagemakerStudio/SagemakerStudioList')));
const SagemakerStudioView = Loadable(lazy(() => import('./views/SagemakerStudio/SagemakerStudioView')));
const SagemakerStudioCreateForm = Loadable(lazy(() => import('./views/SagemakerStudio/SagemakerStudioCreateForm')));

const DashboardList = Loadable(lazy(() => import('./views/Dashboards/DashboardList')));
const DashboardImportForm = Loadable(lazy(() => import('./views/Dashboards/DashboardImportForm')));
const DashboardEditForm = Loadable(lazy(() => import('./views/Dashboards/DashboardEditForm')));
const DashboardView = Loadable(lazy(() => import('./views/Dashboards/DashboardView')));
const DashboardSessionStarter = Loadable(lazy(() => import('./views/Dashboards/DashboardSessionStarter')));

const PipelineList = Loadable(lazy(() => import('./views/Pipelines/PipelineList')));
const PipelineView = Loadable(lazy(() => import('./views/Pipelines/PipelineView')));
const PipelineCreateForm = Loadable(lazy(() => import('./views/Pipelines/PipelineCreateForm')));
const PipelineEditForm = Loadable(lazy(() => import('./views/Pipelines/PipelineEditForm')));

const WarehouseCreateForm = Loadable(lazy(() => import('./views/Warehouses/WarehouseCreateForm')));
const WarehouseView = Loadable(lazy(() => import('./views/Warehouses/WarehouseView')));
const WarehouseEditForm = Loadable(lazy(() => import('./views/Warehouses/WarehouseEditForm')));
const WarehouseImportForm = Loadable(lazy(() => import('./views/Warehouses/WarehouseImportForm')));

const ShareList = Loadable(lazy(() => import('./views/Shares/ShareList')));
const ShareView = Loadable(lazy(() => import('./views/Shares/ShareView')));

const WorksheetList = Loadable(lazy(() => import('./views/Worksheets/WorksheetList')));
const WorksheetView = Loadable(lazy(() => import('./views/Worksheets/WorksheetView')));
const WorksheetCreateForm = Loadable(lazy(() => import('./views/Worksheets/WorksheetCreateForm')));

const GlossaryList = Loadable(lazy(() => import('./views/Glossaries/GlossaryList')));
const GlossaryView = Loadable(lazy(() => import('./views/Glossaries/GlossaryView')));
const GlossaryCreateForm = Loadable(lazy(() => import('./views/Glossaries/GlossaryCreateForm')));

const AdministrationView = Loadable(lazy(() => import('./views/Administration/AdministrationView')));

const CostReportView = Loadable(lazy(() => import('./views/CostReport/CostReportView')));

const ReleaseNotesView = Loadable(lazy(() => import('./views/ReleaseNotes/ReleaseNoteView')));

const routes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <DefaultLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to="/notebooks" />,
      },
      {
        path: '/organizations',
        children: [
          {
            path: '',
            element: <OrganizationList />,
          },
          {
            path: ':uri',
            element: <OrganizationView />,
          },
          {
            path: ':uri/edit',
            element: <OrganizationEditForm />,
          },
          {
            path: '/organizations/new',
            element: <OrganizationCreateForm />,
          },
          {
            path: ':uri/link',
            element: <EnvironmentCreateForm />,
          },
        ],
      },
      {
        path: '/warehouse/:uri',
        element: <WarehouseView />,
      },
      {
        path: '/warehouse/:uri/edit',
        element: <WarehouseEditForm />,
      },
      {
        path: '/workspaces',
        children: [
          {
            path: '',
            element: <EnvironmentList />,
          },
          {
            path: ':uri',
            element: <EnvironmentView />,
          },
          {
            path: ':uri/edit',
            element: <EnvironmentEditForm />,
          },
          {
            path: ':uri/warehouses/new',
            element: <WarehouseCreateForm />,
          },
          {
            path: ':uri/warehouses/import',
            element: <WarehouseImportForm />,
          },
        ],
      },
      {
        path: '/my-datasets',
        element: <DatasetList />,
      },
      {
        path: '/datasets',
        children: [
          {
            path: '',
            element: <Datasets />,
          },
          {
            path: ':uri',
            element: <DatasetView />,
          },
          {
            path: '/datasets/new',
            element: <DatasetCreateForm />,
          },
          {
            path: '/datasets/import',
            element: <DatasetImportForm />,
          },
          {
            path: ':uri/edit',
            element: <DatasetEditForm />,
          },
          {
            path: ':uri/edit',
            element: <DatasetEditForm />,
          },
          {
            path: '/datasets/table/:uri',
            element: <TableView />,
          },
          {
            path: '/datasets/table/:uri/edit',
            element: <TableEditForm />,
          },
          {
            path: ':uri/newfolder',
            element: <FolderCreateForm />,
          },
          {
            path: '/datasets/folder/:uri',
            element: <FolderView />,
          },
          {
            path: '/datasets/folder/:uri/edit',
            element: <FolderEditForm />,
          },
        ],
      },
      {
        path: '/notebooks',
        children: [
          {
            path: '',
            element: <NotebookList />,
          },
          {
            path: ':uri',
            element: <NotebookView />,
          },
          {
            path: '/notebooks/new',
            element: <NotebookCreateForm />,
          },
        ],
      },
      {
        path: '/sagemakerstudio/userprofile',
        children: [
          {
            path: '',
            element: <SagemakerStudioList />,
          },
          {
            path: ':uri',
            element: <SagemakerStudioView />,
          },
          {
            path: '/sagemakerstudio/userprofile/new',
            element: <SagemakerStudioCreateForm />,
          },
        ],
      },
      {
        path: '/dashboards',
        children: [
          {
            path: '',
            element: <DashboardList />,
          },
          {
            path: ':uri',
            element: <DashboardView />,
          },
          {
            path: '/dashboards/session',
            element: <DashboardSessionStarter />,
          },
          {
            path: '/dashboards/import',
            element: <DashboardImportForm />,
          },
          {
            path: ':uri/edit',
            element: <DashboardEditForm />,
          },
        ],
      },
      {
        path: '/pipelines',
        children: [
          {
            path: '',
            element: <PipelineList />,
          },
          {
            path: ':uri',
            element: <PipelineView />,
          },
          {
            path: '/pipelines/new',
            element: <PipelineCreateForm />,
          },
          {
            path: ':uri/edit',
            element: <PipelineEditForm />,
          },
        ],
      },
      {
        path: '/access-requests',
        children: [
          {
            path: '',
            element: <ShareList />,
          },
          {
            path: ':uri',
            element: <ShareView />,
          },
        ],
      },
      {
        path: '/worksheets',
        children: [
          {
            path: '',
            element: <WorksheetList />,
          },
          {
            path: ':uri',
            element: <WorksheetView />,
          },
          {
            path: '/worksheets/new',
            element: <WorksheetCreateForm />,
          },
        ],
      },
      {
        path: '/glossaries',
        children: [
          {
            path: '',
            element: <GlossaryList />,
          },
          {
            path: ':uri',
            element: <GlossaryView />,
          },
          {
            path: '/glossaries/new',
            element: <GlossaryCreateForm />,
          },
        ],
      },
      {
        path: '/administration',
        children: [
          {
            path: '',
            element: <AdministrationView />,
          },
        ],
      },
      {
        path: '/cost-report',
        children: [
          {
            path: '',
            element: <CostReportView />,
          },
        ],
      },
      {
        path: '/release-notes',
        children: [
          {
            path: '',
            element: <ReleaseNotesView />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: (
      <AuthGuard>
        <DefaultLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
