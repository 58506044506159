import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import CopyIcon from '@material-ui/icons/ContentCopy';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

function CopyIconButton({ text }) {
  const [copied, setCopied] = useState(false);

  const copy = () => {
    setCopied(true);
    navigator.clipboard.writeText(text);
    setTimeout(() => setCopied(false), 1200);
  };

  return (
    <IconButton onClick={copy} variant={`${copied ? 'contained' : 'contained'}`}>
      {copied ? <DoneIcon /> : <CopyIcon />}
    </IconButton>
  );
}

CopyIconButton.propTypes = {
  text: PropTypes.string,
};

export default CopyIconButton;
