import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { SnackbarProvider } from 'notistack';
import { MainProvider } from '@gdo/react-design-library';
import GlobalStyles from './components/GlobalStyles';
import SplashScreen from './components/SplashScreen';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import routes from './routes';
import { createUITheme } from './theme';
import SettingsContext from './contexts/SettingsContext';
import { NotificationProvider } from './providers/NotificationProvider';
import { NotebookControllerProvider } from './providers/NotebookStatusProvider';
import { BookmarkProvider } from './providers/BookmarkProvider';
import TermsOfUse from './components/TermsOfUse';
import UserGuideProvider from './providers/UserGuideProvider';

const App = () => {
  const content = useRoutes(routes);
  const { isInitialized } = useAuth();
  const { settings } = useContext(SettingsContext);
  const [theme, setTheme] = useState(null);

  useScrollReset();

  useEffect(() => {
    if (settings) {
      setTheme(
        createUITheme({
          direction: settings.direction,
          responsiveFontSizes: settings.responsiveFontSizes,
          roundedCorners: settings.roundedCorners,
          theme: settings.theme,
        }),
      );
    }
  }, [settings]);

  if (!theme) {
    return null;
  }

  return (
    <MainProvider>
      <ThemeProvider theme={theme}>
        <SnackbarProvider dense maxSnack={3} hideIconVariant>
          <NotificationProvider>
            <NotebookControllerProvider>
              <BookmarkProvider>
                <UserGuideProvider>
                  <GlobalStyles />
                  {isInitialized ? content : <SplashScreen />}
                  <TermsOfUse />
                </UserGuideProvider>
              </BookmarkProvider>
            </NotebookControllerProvider>
          </NotificationProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </MainProvider>
  );
};

export default App;
