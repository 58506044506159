const common = {
  sqlWorksheetJwtSecret: 'secret',
  Metadata: {
    GlueBusinessFilters: ['al.wbl', 'al.region', 'al.zone', 'al.branch', 'al.domain', 'al.confidentiality', 'al.hub', 'al.sub_domain', 'al.system'],
  },
};
let config;
switch (process.env.REACT_APP_STAGE) {
  case 'local':
    config = {
      ...common,
      apiGateway: {
        REGION: 'eu-west-1',
        URL: 'http://localhost:4000/graphql',
        ESURL: 'http://localhost:4000/esproxy',
      },
      cognito: {
        REGION: 'eu-west-1',
        USER_POOL_ID: 'eu-west-1_unXnE8Stu',
        APP_CLIENT_ID: '5lqatko1no7no6q9m9gkiqunis',
        DOMAIN: 'datahubdeveuwest1167842893001.auth.eu-west-1.amazoncognito.com',
        REDIRECT_URI: 'http://localhost:8080',
        SIGNOUT_URL: 'http://localhost:8080',
        TYPE: 'token',
        SCOPE: ['email', 'phone', 'openid', 'profile'],
      },
      links: {
        dataPortal: 'http://localhost:8081',
        developerDocumentation: 'https://dyj5aht0xjbz7.cloudfront.net',
        userGuide: 'https://sites.google.com/airliquide.com/gdo-datastudio/features',
        support:
          'mailto:ww-datahub-support@airliquide.com?subject=%5BDatahub%5D%20-%20Support&body=Hello%2C%0D%0A%0D%0AEnvironment%3A%20Development%0D%0AType%3A%20Feature%20%2F%20Bug%20%2F%20Other%0D%0A%0D%0ADescription%3A%0D%0A%0D%0A',
      },
    };
    break;
  case 'localToDev':
    config = {
      ...common,
      apiGateway: {
        REGION: 'eu-west-1',
        URL: 'https://zbty5atvg3.execute-api.eu-west-1.amazonaws.com/prod/graphql/api',
        ESURL: 'https://zbty5atvg3.execute-api.eu-west-1.amazonaws.com/prod/search/api',
      },
      cognito: {
        REGION: 'eu-west-1',
        USER_POOL_ID: 'eu-west-1_unXnE8Stu',
        APP_CLIENT_ID: '5lqatko1no7no6q9m9gkiqunis',
        DOMAIN: 'datahubdeveuwest1167842893001.auth.eu-west-1.amazoncognito.com',
        REDIRECT_URI: 'http://localhost:8080',
        SIGNOUT_URL: 'http://localhost:8080',
        TYPE: 'token',
        SCOPE: ['email', 'phone', 'openid', 'profile'],
      },
      links: {
        dataPortal: 'https://dataportal-dev.airliquide.com',
        developerDocumentation: 'https://dyj5aht0xjbz7.cloudfront.net',
        userGuide: 'https://sites.google.com/airliquide.com/gdo-datastudio/features',
        support:
          'mailto:ww-datahub-support@airliquide.com?subject=%5BDatahub%5D%20-%20Support&body=Hello%2C%0D%0A%0D%0AEnvironment%3A%20Development%0D%0AType%3A%20Feature%20%2F%20Bug%20%2F%20Other%0D%0A%0D%0ADescription%3A%0D%0A%0D%0A',
      },
    };
    break;
  case 'localToPprd':
    config = {
      ...common,
      apiGateway: {
        REGION: 'eu-west-1',
        URL: 'https://api-datahub-pprd.airliquide.com/prod/graphql/api',
        ESURL: 'https://api-datahub-pprd.airliquide.com/prod/search/api',
      },
      cognito: {
        REGION: 'eu-west-1',
        USER_POOL_ID: 'eu-west-1_0o9rGDG3m',
        APP_CLIENT_ID: '2c8240k3nhq061pp7f5o54ee1n',
        DOMAIN: 'datahubprodeuwest1942871097318.auth.eu-west-1.amazoncognito.com',
        REDIRECT_URI: 'http://localhost:8080',
        SIGNOUT_URL: 'http://localhost:8080',
        TYPE: 'token',
        SCOPE: ['email', 'phone', 'openid', 'profile'],
      },
      links: {
        dataPortal: 'https://dataportal-pprd.airliquide.com',
        developerDocumentation: 'https://d19t9fm75v5xsx.cloudfront.net',
        userGuide: 'https://sites.google.com/airliquide.com/gdo-datastudio/features',
        support:
          'mailto:ww-datahub-support@airliquide.com?subject=%5BDatahub%5D%20-%20Support&body=Hello%2C%0D%0A%0D%0AEnvironment%3A%20Production%0D%0AType%3A%20Feature%20%2F%20Bug%20%2F%20Other%0D%0A%0D%0ADescription%3A%0D%0A%0D%0A',
      },
    };
    break;
  case 'localToProd':
    config = {
      ...common,
      apiGateway: {
        REGION: 'eu-west-1',
        URL: 'https://api-datahub-prod.airliquide.com/prod/graphql/api',
        ESURL: 'https://api-datahub-prod.airliquide.com/prod/search/api',
      },
      cognito: {
        REGION: 'eu-west-1',
        USER_POOL_ID: 'eu-west-1_0o9rGDG3m',
        APP_CLIENT_ID: '2c8240k3nhq061pp7f5o54ee1n',
        DOMAIN: 'datahubprodeuwest1942871097318.auth.eu-west-1.amazoncognito.com',
        REDIRECT_URI: 'http://localhost:8080',
        SIGNOUT_URL: 'http://localhost:8080',
        TYPE: 'token',
        SCOPE: ['email', 'phone', 'openid', 'profile'],
      },
      links: {
        dataPortal: 'https://dataportal.airliquide.com',
        developerDocumentation: 'https://d19t9fm75v5xsx.cloudfront.net',
        userGuide: 'https://sites.google.com/airliquide.com/gdo-datastudio/features',
        support:
          'mailto:ww-datahub-support@airliquide.com?subject=%5BDatahub%5D%20-%20Support&body=Hello%2C%0D%0A%0D%0AEnvironment%3A%20Production%0D%0AType%3A%20Feature%20%2F%20Bug%20%2F%20Other%0D%0A%0D%0ADescription%3A%0D%0A%0D%0A',
      },
    };
    break;
  case 'dev':
    config = {
      ...common,
      apiGateway: {
        REGION: 'eu-west-1',
        URL: 'https://zbty5atvg3.execute-api.eu-west-1.amazonaws.com/prod/graphql/api',
        ESURL: 'https://zbty5atvg3.execute-api.eu-west-1.amazonaws.com/prod/search/api',
      },
      cognito: {
        REGION: 'eu-west-1',
        USER_POOL_ID: 'eu-west-1_unXnE8Stu',
        APP_CLIENT_ID: '5lqatko1no7no6q9m9gkiqunis',
        DOMAIN: 'datahubdeveuwest1167842893001.auth.eu-west-1.amazoncognito.com',
        REDIRECT_URI: 'https://datastudio-dev.airliquide.com',
        SIGNOUT_URL: 'https://datastudio-dev.airliquide.com',
        TYPE: 'token',
        SCOPE: ['email', 'phone', 'openid', 'profile'],
      },
      links: {
        dataPortal: 'https://dataportal-dev.airliquide.com',
        developerDocumentation: 'https://dyj5aht0xjbz7.cloudfront.net',
        userGuide: 'https://sites.google.com/airliquide.com/gdo-datastudio/features',
        support:
          'mailto:ww-datahub-support@airliquide.com?subject=%5BDatahub%5D%20-%20Support&body=Hello%2C%0D%0A%0D%0AEnvironment%3A%20Development%0D%0AType%3A%20Feature%20%2F%20Bug%20%2F%20Other%0D%0A%0D%0ADescription%3A%0D%0A%0D%0A',
      },
    };
    break;
  case 'pprd':
    config = {
      ...common,
      apiGateway: {
        REGION: 'eu-west-1',
        URL: 'https://api-datahub-pprd.airliquide.com/prod/graphql/api',
        ESURL: 'https://api-datahub-pprd.airliquide.com/prod/search/api',
      },
      cognito: {
        REGION: 'eu-west-1',
        USER_POOL_ID: 'eu-west-1_WP3diK4E4',
        APP_CLIENT_ID: '5oeommath2octc6nmepcufb38i',
        DOMAIN: 'datahubpprdeuwest1544247218144.auth.eu-west-1.amazoncognito.com',
        REDIRECT_URI: 'https://datastudio-pprd.airliquide.com',
        SIGNOUT_URL: 'https://datastudio-pprd.airliquide.com',
        TYPE: 'token',
        SCOPE: ['email', 'phone', 'openid', 'profile'],
      },
      links: {
        dataPortal: 'https://dataportal-pprd.airliquide.com',
        developerDocumentation: 'https://d5dzbdijgaj07.cloudfront.net',
        userGuide: 'https://sites.google.com/airliquide.com/gdo-datastudio/features',
        support:
          'mailto:ww-datahub-support@airliquide.com?subject=%5BDatahub%5D%20-%20Support&body=Hello%2C%0D%0A%0D%0AEnvironment%3A%20Preproduction%0D%0AType%3A%20Feature%20%2F%20Bug%20%2F%20Other%0D%0A%0D%0ADescription%3A%0D%0A%0D%0A',
      },
    };
    break;
  case 'prod':
    config = {
      ...common,
      apiGateway: {
        REGION: 'eu-west-1',
        URL: 'https://api-datahub-prod.airliquide.com/prod/graphql/api',
        ESURL: 'https://api-datahub-prod.airliquide.com/prod/search/api',
      },
      cognito: {
        REGION: 'eu-west-1',
        USER_POOL_ID: 'eu-west-1_0o9rGDG3m',
        APP_CLIENT_ID: '2c8240k3nhq061pp7f5o54ee1n',
        DOMAIN: 'datahubprodeuwest1942871097318.auth.eu-west-1.amazoncognito.com',
        REDIRECT_URI: 'https://datastudio.airliquide.com',
        SIGNOUT_URL: 'https://datastudio.airliquide.com',
        TYPE: 'token',
        SCOPE: ['email', 'phone', 'openid', 'profile'],
      },
      links: {
        dataPortal: 'https://dataportal.airliquide.com',
        developerDocumentation: 'https://d19t9fm75v5xsx.cloudfront.net',
        userGuide: 'https://sites.google.com/airliquide.com/gdo-datastudio/features',
        support:
          'mailto:ww-datahub-support@airliquide.com?subject=%5BDatahub%5D%20-%20Support&body=Hello%2C%0D%0A%0D%0AEnvironment%3A%20Production%0D%0AType%3A%20Feature%20%2F%20Bug%20%2F%20Other%0D%0A%0D%0ADescription%3A%0D%0A%0D%0A',
      },
    };
    break;
  default:
    config = {
      ...common,
      apiGateway: {
        REGION: 'eu-west-1',
        URL: 'https://eygeiq3vt8.execute-api.eu-west-1.amazonaws.com/prod/graphql/api',
        ESURL: 'https://eygeiq3vt8.execute-api.eu-west-1.amazonaws.com/prod/search/api',
      },
      cognito: {
        REGION: 'eu-west-1',
        USER_POOL_ID: 'eu-west-1_unXnE8Stu',
        APP_CLIENT_ID: '5lqatko1no7no6q9m9gkiqunis',
        DOMAIN: 'datahubdeveuwest1167842893001.auth.eu-west-1.amazoncognito.com',
        REDIRECT_URI: 'https://datastudio.airliquide.com',
        SIGNOUT_URL: 'https://datastudio.airliquide.com',
        TYPE: 'token',
        SCOPE: ['email', 'phone', 'openid', 'profile'],
      },
      links: {
        developerDocumentation: 'https://dyj5aht0xjbz7.cloudfront.net',
        userGuide: 'https://sites.google.com/airliquide.com/gdo-datastudio/features',
        support:
          'mailto:ww-datahub-support@airliquide.com?subject=%5BDatahub%5D%20-%20Support&body=Hello%2C%0D%0A%0D%0AEnvironment%3A%20Development%0D%0AType%3A%20Feature%20%2F%20Bug%20%2F%20Other%0D%0A%0D%0ADescription%3A%0D%0A%0D%0A',
      },
    };
    break;
}
export default {
  ...config,
};
