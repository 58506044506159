import { ReleaseNoteTimeline } from './types';

const notes: ReleaseNoteTimeline[] = [
  {
    version: 'v1.8',
    datetime: 'June 22, 2023',
    topics: [
      {
        title: 'Data Availibility',
        items: [
          'Initial release of the DataLake synchronization feature',
          'No more manual hard copy of datasets everyday',
          'Data is available as soon as governance releases it',
        ],
        impacts: [],
      },
      {
        title: 'Core Product Update',
        items: [
          'Libraries updated (CDK, Python version, Python libraries, NodeJS version, NodeJS libraries, Dockers images...)',
          'Automatic workspaces update after a release',
          'Bug fixes',
        ],
        impacts: ['Datasets', 'SageMaker', 'Shares'],
      },
      {
        title: 'AWS Consumption Cost',
        items: ['Taxes are now properly handled on daily granularity view.', 'Futures costs are now properly handled on daily granularity view.'],
        impacts: [],
      },
      {
        title: 'Worksheet',
        items: [`It's not more possible to create a worksheet in admin team.`],
        impacts: [],
      },
      {
        title: 'SageMaker Notebooks',
        items: [`Code Server available.`],
        impacts: [],
      },
      {
        title: 'Documentation - User Guide',
        items: [`Tutorial to explain how publish results into BI dashboard`],
        impacts: [],
      },
    ],
  },
  {
    version: 'v1.7',
    datetime: 'Mar 21, 2023',
    topics: [
      {
        title: 'Datasets Availability / Access',
        items: ['It is no longer possible to create or import a dataset into an admin team.'],
        impacts: [],
      },
    ],
  },
  {
    version: 'v1.6',
    datetime: '',
    topics: [
      {
        title: 'Datasets Availability / Access',
        items: ['BIS APAC Workspace Creation & Enrollment.'],
        impacts: [],
      },
      {
        title: 'Product Reliability, Scalability & Observability',
        items: [
          'Multipass SSO migrated to Okta SSO.',
          'Cloudformation stacks status automatically saved in database.',
          'CI/CD improved: Kubernetes runners / stages improved.',
          'IAM Role name not more ask during team and workspace creation.',
          'New "User Guide" available.',
          'Constrained teams to be unique across all workspaces',
          'Fix: Team selection on "Create a new dataset" page fixed. All teams are now exposed.',
        ],
        impacts: [],
      },
      {
        title: 'SageMaker',
        items: [
          `Lifecycle configuration modified to work with the new AWS <a href="https://docs.aws.amazon.com/sagemaker/latest/dg/nbi-al2.html" target="_blank">AL2 platform</a>.`,
          'Package updated: scipy (1.10.0), awswrangler (2.20.0).',
        ],
        impacts: [],
      },
      {
        title: 'SageMaker Studio Notebooks (MVP)',
        items: ['Presigned URL fixed'],
        impacts: [],
      },
    ],
  },
  {
    version: 'v1.5',
    datetime: 'Feb 02, 2023',
    topics: [
      {
        title: 'Datasets Availability / Access',
        items: [
          'Cross workspace  data sharing tests and bugs fix (request from a DS workspace  to dataset hosted on a different  DS workspace)',
          'Airgas CDM sales Import on DataStudio (for Airgas AIR cohort)',
        ],
        impacts: [],
      },
      {
        title: 'DataStudio for AIR Cohorts',
        items: ['New dedicated DS workspace on APAC region (to prepare APAC AIR Cohort)'],
        impacts: [],
      },
      {
        title: 'Product Reliability, Scalability & Observability',
        items: [
          'DataStudio new URL : datastudio.airliquide.com (datahub.airliquide.com still available)',
          'Fix the CORS issue (Dataset API returns nothing for 3-4 minutes every hour)',
          'Bug fix for SQL worksheets',
          'KPI Refactoring to ease extraction',
        ],
        impacts: [],
      },
      {
        title: 'SageMaker Studio Notebooks (MVP)',
        items: [
          'Authorize the use of "ml.m5.xlarge" instance for kernel applications ',
          `Add "jupyterlab-code-formatter" extension in the Jupyter application by default (<a href="https://github.com/ryantam626/jupyterlab_code_formatter" target="_blank">more info</a>)`,
          'Prepare requirements for an end user team tests  (early beta access)',
        ],
        impacts: [],
      },
    ],
  },
  {
    version: 'v1.4',
    datetime: 'Dec 22, 2022',
    topics: [
      {
        title: 'Datasets Access',
        items: [
          'Fixed the broken tables read permission after initial successful permissions setting.',
          'Added daily datasets refresh to ensure all resources are up to date.',
          'Added daily datasets access requests refresh to reinforce the request access implementation.',
        ],
        impacts: [],
      },
      {
        title: 'UI Updates',
        items: [
          'Added the <b>"Start"</b> button on Notebook preview in "stopped" status',
          'Fixed the bug “Cannot scroll pages when adding items in an access request”. \nThe link to the next pages was broken.',
          'Removed (temporarily) Cost monitoring screen to investigate users feedbacks.',
          'Updated the "Product Tour" according to the changes mentioned above',
        ],
        impacts: [],
      },
      {
        title: 'UI Updates (Unified access to datasets and data initiatives through  Data Portal)',
        items: [
          'Redirected the Datasets entry menu to Data Portal Datasets',
          'Replaced <b>Team</b> term by <b>Initiative</b>. So, any team list available in Data Studio will be replaced by the Initiative list available in Data Portal',
          'Added a more explicit team name (label instead of groupUri) ',
        ],
        impacts: [],
      },
      {
        title: 'Product Reliability & Scalability',
        items: [
          'Extended teams quota per workspace from 25 to more than 60',
          'Optimized cost of the gitlab runners',
          'Updated workspaces pivot role in DEV & PPRD',
          'Fixed throttling for AWS stack updates',
        ],
        impacts: [],
      },
      {
        title: 'SageMaker Studio Notebooks Feature Preparation',
        items: ['Added notebook examples for Sagemaker Studio usage', 'Fixed a bug where ATHENA_WORKGROUP returns None'],
        impacts: [],
      },
    ],
  },
  {
    version: 'v1.3',
    datetime: 'Nov 22, 2022',
    topics: [
      {
        title: 'Major upgrade of data access feature',
        items: [
          'New function was added to the first example notebooks “01-getting-started.ipynb” in order to retrieve the correct database name from its prefix name.',
        ],
        impacts: [
          'Several conda environments will be available. Please make sure using only the AL customized default_kernel, other kernels are not AL customized and thus should not be used.',
        ],
      },
      {
        title: 'New Datasets',
        items: ['GROUP Location (Site & Facility) Location', 'GROUP Forex Foreign Exchange Rate WW', 'GROUP Legal Entity Legal Entity WW'],
        impacts: [],
      },
      {
        title: 'UX/UI improvements',
        items: [
          'Product Tour to virtually guide users through the basics and necessary features.',
          'Added <b>"Request Access"</b> button for Dataset, Table and Folder directly in the concerned views.',
          'Now possible to restart a notebook in <b>"failed"</b> status.',
          'Not more possible to <b>"open"</b> a <b>"failed"</b> notebook.',
        ],
        impacts: [],
      },
      {
        title: 'Product Scalability requirements',
        items: [
          'Enroll new DataStudio Accounts for Europe & Americas BIS',
          'Extend data initiatives quota per DataStudio Account',
          'Extend Notebooks quota per DataStudio Account',
          'Extend Data Sharing Quota per Account & Cross accounts',
          'Extend S3 Buckets quotas',
        ],
        impacts: [],
      },
      {
        title: 'New KPI',
        items: ['Notebooks Usage KPI'],
        impacts: [],
      },
    ],
  },
  {
    version: 'v1.2',
    datetime: 'Sept 1, 2022',
    topics: [
      {
        title: 'Cost monitoring',
        items: ['New dashboards have been created to track your usages.'],
        impacts: [],
      },
      {
        title: 'Usage Monitoring (KPI)',
        items: ['KPI for number of notebook instances and Athena requests per team available in the API.'],
        impacts: [],
      },
      {
        title: 'Release note',
        items: ['Public <b>"Release notes"</b> page has been created.'],
        impacts: [],
      },
      {
        title: 'SageMaker Studio Integration',
        items: ['Service available for specific workspace (MLOps initiative) for beta testing including AL environment customisation.'],
        impacts: [],
      },
      {
        title: 'Observability',
        items: ['User session data is streamed to Cloudwatch RUM to monitor potential errors, latency, or application-wide user journey inconvenience.'],
        impacts: [],
      },
    ],
  },
  {
    version: 'v1.1',
    datetime: 'July 1, 2022',
    topics: [
      {
        title: 'Connected Notebooks',
        items: [
          'New AL custom Libraries : \nDaxer for Azure Analytics service DAX queries \nBlobber and Azure Blob queries \nScada-API for access scada data',
          'Example notebooks are available in examples/external-data-services for Daxer Blobber & scada API',
          'Athena workgroup is available through ATHENA_WORKGROUP environment variable ',
          'Example notebooks are updated for the use of Athena workgroup in wrangler SQL queries',
          'Optimization of notebooks start-up time',
        ],
        impacts: [
          'Security has been enforced for dataset accesses. You may encounter restrictions to previously running workflows, in that case request access to the dataset via the DataStudio interface.',
        ],
      },
      {
        title: 'Dataset Import',
        items: ['Maximo Data', 'Intelex Data'],
        impacts: [],
      },
      {
        title: 'User Guide',
        items: ['How to access and use tutorial Notebooks', 'Extended services documentation'],
        impacts: [],
      },
      {
        title: 'Developer Guide',
        items: ['Integration of Release 1.1 updates'],
        impacts: [],
      },
    ],
  },
  {
    version: 'v1.0',
    datetime: 'Jun 5, 2022',
    topics: [
      {
        title: 'Connected Notebooks',
        items: [
          'Notebook instances configuration (choice of VPC, subnet, volume size and instance type)',
          'Instance default is ml.m5.xlarge with 32GB EBS',
          'Execute time extension by default on notebook cells',
          'Mirror pip repository with AL Nexus',
          'Installation of CA certificates bundle ( scada API… )',
          'Improved Notebooks IP allocation strategy',
        ],
        impacts: [],
      },
      {
        title: 'Dataset',
        items: [
          'Data access isolation by team',
          'Dataset upvote and chat capability (one chat thread per dataset)',
          'Dataset & Workspace customizable Key/Value Tags',
          'Glue business Metadata available in overview',
          'Fix metric profiling',
          'Dataset import (admin mode) : s3 bucket is mandatory, and ability to add a  confidentiality metadata for new imported datasets',
        ],
        impacts: [],
      },
      {
        title: 'Catalog',
        items: ['New catalog filters (Type, data domain, sub domain, WBL, source..)'],
        impacts: [],
      },
      {
        title: 'Infrastructure and security',
        items: [
          'CloudWatch RUM for user monitoring (with alarms to SNS Topics)',
          'Stable workspace onboarding (without deletion of Lake Formation admins and Quicksight no more mandatory)',
          'Workgroups Athena per team',
          'Permissions IAM worksheets & notebooks through the team role, as well as application permissions',
          'AWS tags per workspace and teams',
          'Overall Solution robustness (access to cdk logs for all resources, scheduled update of workspace/dataset stacks)',
          'Development workflow enhanced (docker-compose with auto-reload)',
        ],
        impacts: ['Read/Write permission at team level rather than workspace.'],
      },
      {
        title: 'Authentication',
        items: ['Successfully log into Data Studio while belonging to more than 6 AD Groups'],
        impacts: [],
      },
    ],
  },
];

export { notes };
