import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import React, { useContext, useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarIcon from '@mui/icons-material/Star';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import useClient from '../../hooks/useClient';
import { BookmarkContext } from '../../providers/BookmarkProvider';
import StarDatasetItem from './StarDatasetItem';

const useStyles = makeStyles(() => ({
  accordionOverrides: {
    borderRadius: '0 !important',
  },
}));

/**
 * @description Component displaying the list of user's bookmark
 * @return {JSX.Element}
 */
export default function StarredMenu() {
  const classes = useStyles();
  const client = useClient();
  const { fetchDatasetBookmarks, datasetBookmarks, fetchedNewBookmark } = useContext(BookmarkContext);
  const [defaultExpanded, setDefaultExpended] = useState();
  const [expended, setExpended] = useState();

  /**
   * @description Expend the accordion if new bookmark
   */
  useEffect(() => {
    if (fetchedNewBookmark) {
      setExpended(true);
    }
  }, [fetchedNewBookmark]);

  /**
   * @description Fetch DatasetBookmarks when client ready and no bookmarks yet
   */
  useEffect(() => {
    const fetchData = async () => {
      if (client && !datasetBookmarks && fetchDatasetBookmarks) {
        await fetchDatasetBookmarks();
      }
    };

    fetchData();
  }, [client, datasetBookmarks]);

  /**
   * @description set the default state of the accordion based on the local storage then the user get his last setting
   */
  useEffect(() => {
    if (typeof defaultExpanded === 'undefined') {
      const storageValue = localStorage.getItem('data-studio-starred-menu-expended');
      setDefaultExpended(storageValue === 'true');
      setExpended(storageValue === 'true');
    }
  }, [defaultExpanded]);

  return (
    <>
      {typeof defaultExpanded !== 'undefined' && (
        <Accordion
          className={classes.accordionOverrides}
          disableGutters
          defaultExpanded={defaultExpanded}
          expanded={expended}
          onChange={(e, ex) => {
            setExpended(ex);
            localStorage.setItem('data-studio-starred-menu-expended', ex.toString());
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <StarIcon sx={{ mr: 1 }} fontSize="small" />
              <Typography>Starred</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ pl: 0, pr: 0, maxHeight: '185px', overflow: 'auto' }}>
            {datasetBookmarks && (
              <>
                {!!datasetBookmarks.length &&
                  datasetBookmarks.map((datasetBookmark) => <StarDatasetItem datasetBookmark={datasetBookmark} key={datasetBookmark.datasetBookmarkUri} />)}
                {!datasetBookmarks.length && (
                  <Box sx={{ pl: 2 }}>
                    <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                      You don&lsquo;t have any starred
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
}
