import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { SET_ERROR } from '../store/errorReducer';
import { useDispatch } from '../store';

const useGroups = (filters) => {
  const dispatch = useDispatch();
  const [groups, setGroups] = useState(null);
  const fetchGroups = async () => {
    const session = await Auth.currentSession();
    const cognitoGroups = session.getIdToken().payload['cognito:groups'];
    const samlGroups = session.getIdToken().payload['custom:ad.groups']
      ? session.getIdToken().payload['custom:ad.groups'].replace('[', '').replace(']', '').replace(', ', ',').split(',')
      : [];

    let groups = [].concat(cognitoGroups).concat(samlGroups).filter(Boolean);

    if (filters && filters.exclude && filters.exclude.okta) {
      groups = groups.filter((group) => !group.endsWith('_Okta'));
    }
    if (filters && filters.exclude && filters.exclude.tenant) {
      groups = groups.filter((group) => group !== 'DHAdministrators');
    }

    setGroups(groups);
  };

  useEffect(() => {
    if (!groups) {
      fetchGroups().catch((e) => dispatch({ type: SET_ERROR, error: e.message }));
    }
  });
  return groups;
};

export default useGroups;
