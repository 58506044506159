import React from 'react';
import { Button } from '@material-ui/core';

/**
 * @description Button to redirect to "Open a MMS ticket"
 * @returns {JSX.Element}
 */
const OpenMmsTicket = () => (
  <Button
    sx={{ p: 0.1, fontSize: 12 }}
    target="_blank"
    data-tour="step-feedback"
    color="primary"
    href="https://airliquide.service-now.com/myitcorner?id=al_sc_cat_item&sys_id=ab7cf875dbe3a3404260b5ca6896192d"
  >
    Open a ticket
  </Button>
);

export default OpenMmsTicket;
