import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import { List, ListSubheader } from '@material-ui/core';
import NavItem from './NavItem';
import { useState, useEffect } from 'react';
import useSettings from '../hooks/useSettings';
import useClient from '../hooks/useClient';
import * as Defaults from '../components/defaults';
import listSagemakerStudioUserProfiles from '../api/SagemakerStudio/listSagemakerStudioUserProfiles';

function NavSection(props) {
  const { items, pathname, title, admin, userOnly, betaAccess, ...other } = props;
  const [SMStudioBetaAccess, setSMStudioBetaAccess] = useState(false);
  const { settings } = useSettings();
  const client = useClient();

  useEffect(() => {
    if (client && !settings.isAdvancedMode && items.filter((e) => e.betaAccess)) {
      fetchSMStudioBetaAccess().then((response) => setSMStudioBetaAccess(response));
    }
  }, [client, settings.isAdvancedMode]);

  const canViewSectionElement = (sectionElement) =>
    (!sectionElement.admin && !sectionElement.userOnly && !sectionElement.betaAccess) ||
    (sectionElement.admin && settings.isAdvancedMode) ||
    (sectionElement.userOnly && !settings.isAdvancedMode) ||
    (sectionElement.betaAccess && SMStudioBetaAccess);

  const fetchSMStudioBetaAccess = async () => {
    const response = await client.query(listSagemakerStudioUserProfiles(Defaults.DefaultFilter));

    if (!response.errors) {
      return true;
    }
    return false;
  };

  const renderNavItems = ({ depth = 0, items, pathname }) => (
    <List disablePadding>
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item) =>
          reduceChildRoutes({
            acc,
            item,
            pathname,
            depth,
          }),
        [],
      )}
    </List>
  );

  const reduceChildRoutes = ({ acc, pathname, item, depth }) => {
    const key = `${item.title}-${depth}`;
    const exactMatch = item.path
      ? !!matchPath(
          {
            path: item.path,
            end: false,
          },
          pathname,
        )
      : false;

    if (item.children) {
      const partialMatch = item.path
        ? !!matchPath(
            {
              path: item.path,
              end: false,
            },
            pathname,
          )
        : false;

      acc.push(
        <NavItem active={partialMatch} depth={depth} icon={item.icon} info={item.info} key={key} open={partialMatch} path={item.path} title={item.title}>
          {renderNavItems({
            depth: depth + 1,
            items: item.children,
            pathname,
          })}
        </NavItem>,
      );
    } else {
      acc.push(
        canViewSectionElement(item) && (
          <div key={key} data-tour={item.tour}>
            <NavItem active={exactMatch} depth={depth} icon={item.icon} info={item.info} key={key} path={item.path} title={item.title} />
          </div>
        ),
      );
    }

    return acc;
  };

  return canViewSectionElement({ admin, userOnly, betaAccess }) ? (
    <List
      subheader={
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: 'text.primary',
            fontSize: '0.75rem',
            lineHeight: 2.5,
            fontWeight: 700,
            textTransform: 'uppercase',
          }}
        >
          {title}
        </ListSubheader>
      }
      {...other}
    >
      {renderNavItems({
        items,
        pathname,
      })}
    </List>
  ) : null;
}

NavSection.propTypes = {
  items: PropTypes.array,
  userOnly: PropTypes.bool,
  admin: PropTypes.bool,
  betaAccess: PropTypes.bool,
  pathname: PropTypes.string,
  title: PropTypes.string,
};

export default NavSection;
