import { gql } from 'apollo-boost';

/**
 * @description Returns UserProfile object.
 * @returns {Object}
 */
const getUserProfile = () => ({
  query: gql`
    query GetUserProfile {
      getUserProfile {
        username
        bio
        b64EncodedAvatar
        created
        hasAcceptedTermsOfUseAt
        updated
        tags
      }
    }
  `,
});

export default getUserProfile;
