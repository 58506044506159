import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useClient from '../hooks/useClient';
import listMyDatasetBookmarks from '../api/DatasetBookmark/listMyDatasetBookmarks';
import createMyDatasetBookmark from '../api/DatasetBookmark/createMyDatasetBookmark';
import deleteMyDatasetBookmark from '../api/DatasetBookmark/deleteMyDatasetBookmark';

const BookmarkContext = createContext({});

/**
 * @description Bookmark provider element
 * @param {BookmarkProvider.propTypes} props
 * @return {JSX.Element}
 */
function BookmarkProvider({ children }) {
  const client = useClient();
  const [datasetBookmarks, setDatasetBookmarks] = useState(null);
  const [fetchedNewBookmark, setFetchedNewBookmark] = useState(false);

  /**
   * @description fetchDatasetBookMark the list of user's bookmark
   * @return {Promise<void>}
   */
  const fetchDatasetBookmarks = async () => {
    try {
      const listMyDatasetBookmarksResponse = await client.query(
        listMyDatasetBookmarks({
          filter: {
            page: 1,
            pageSize: 1000,
          },
        }),
      );
      setDatasetBookmarks(listMyDatasetBookmarksResponse.data.listMyDatasetBookmarks.nodes);
      if (datasetBookmarks) {
        if (listMyDatasetBookmarksResponse.data.listMyDatasetBookmarks.nodes.length > datasetBookmarks.length) {
          setFetchedNewBookmark(true);
        } else {
          setFetchedNewBookmark(false);
        }
      } else if (listMyDatasetBookmarksResponse.data.listMyDatasetBookmarks.nodes.length) {
        setFetchedNewBookmark(true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * @description create a user bookmark for a specific dataset
   * @return {Promise<void>}
   */
  const createDatasetBookmark = async (datasetUri) => {
    try {
      await client.mutate(createMyDatasetBookmark(datasetUri));
      await fetchDatasetBookmarks();
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * @description delete a user bookmark for a specific dataset
   * @return {Promise<void>}
   */
  const deleteDatasetBookmark = async (datasetBookmarkUri) => {
    try {
      await client.mutate(deleteMyDatasetBookmark(datasetBookmarkUri));
      await fetchDatasetBookmarks();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (client) {
      fetchDatasetBookmarks();
    }
  }, [client]);

  return (
    <BookmarkContext.Provider
      value={{
        fetchDatasetBookmarks,
        datasetBookmarks,
        createDatasetBookmark,
        deleteDatasetBookmark,
        fetchedNewBookmark,
      }}
    >
      {children}
    </BookmarkContext.Provider>
  );
}

BookmarkProvider.propTypes = {
  children: PropTypes.node,
};

export { BookmarkContext, BookmarkProvider };
