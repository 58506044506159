import { gql } from 'apollo-boost';

/**
 * @description Returns all DatasetBookmarks.
 * @param {{pageSize: number, page: number}} filter
 * @returns {Object[]}
 */
const listMyDatasetBookmarks = ({ filter }) => ({
  variables: {
    filter,
  },
  query: gql`
    query ListMyDatasetBookmarks($filter: DatasetBookmarkFilter) {
      listMyDatasetBookmarks(filter: $filter) {
        count
        pageSize
        page
        previousPage
        hasNext
        hasPrevious
        nodes {
          datasetBookmarkUri
          datasetUri
          dataset {
            datasetUri
            label
            name
            description
          }
          createdAt
          updatedAt
          deletedAt
        }
      }
    }
  `,
});

export default listMyDatasetBookmarks;
