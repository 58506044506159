import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import useGroups from '../hooks/useGroups';
import LicenseRequired from './LicenseRequired';

function AuthGuard(props) {
  const { children } = props;
  const auth = useAuth();
  const groups = useGroups();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    auth.login();

    return null;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{!groups || groups.length ? children : <LicenseRequired />}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
